import Vue from "vue";
import VueRouter from "vue-router";
import { LoginRepository } from "@/infrastructure/repository/login";
import { PaymentRepository } from "@/infrastructure/repository/payment";
import { ReceivedInvoicingRepository } from "@/infrastructure/repository/receive_invoicing";
import { InvitationRepository } from "@/infrastructure/repository/invitation";
import { EmployeeRepository } from "@/infrastructure/repository/employee/employee_repository";
import { AuthInfoRepository } from "@/infrastructure/repository/auth_info";
import { ApplicationRepository } from "@/infrastructure/repository/application";
import { SmsRepository } from "@/infrastructure/repository/sms/sms_repository";
import { InvoicingRepository } from "@/infrastructure/repository/invoicing/invoicing_repository";
import { BalanceRepository } from "@/infrastructure/repository/balance";
import { AccountRepository } from "@/infrastructure/repository/account";
import { MemberRepository } from "@/infrastructure/repository/member";
import { InformationRepository } from "@/infrastructure/repository/information";
import { authenticate } from "@/service/auth_service";
import { PasswordResetRepository } from "@/infrastructure/repository/password_reset_repository";
import { guardInjection } from "./guard_injection";
import { noAuthFlow } from "./no_auth_flow";
import { authFlow } from "./auth_flow";
import { NotificationRepository } from "@/infrastructure/repository/notification/notification_repository";
import { OperationLogRepository } from "@/infrastructure/repository/operation_log_repository/operation_log_repository";
import { OAuthRepository } from "@/infrastructure/repository/oauth/oauth_repository";
import { BankRepository } from "@/infrastructure/repository/bank/bank_repository";
import { FileRepository } from "@/infrastructure/repository/file/file_repository";
import { GanbRepository } from "@/infrastructure/repository/ganb/ganb_repository";
import { ExternalRepository } from "@/infrastructure/repository/external/external_repository";
import { ServiceBillRepository } from "@/infrastructure/repository/service_bill/service_bill_repository";

Vue.use(VueRouter);

// TODO: main.jsと同じ階層にinjectionを移動したい
export const setupRepository = {
  auth: new AuthInfoRepository(),
  sms: new SmsRepository(),
  informationRepository: new InformationRepository(),
  bankRepository: new BankRepository(),
  notificationRepository: new NotificationRepository(),
  login: new LoginRepository(),
};

const routes = [
  {
    path: "/",
    meta: {},
    redirect: () => (authenticate() ? "/home" : "/login"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
    props: (route) => ({
      redirect: route.query.redirect,
      repository: new LoginRepository(),
    }),
  },
  {
    path: "/login-password-send-mail",
    name: "LoginPasswordSendMail",
    component: () => import("@/views/LoginPasswordSendMail.vue"),
    props: () => ({ repository: new PasswordResetRepository() }),
  },
  {
    // TODO: login-xxx認証済みのとき遷移できないようにガード追加
    path: "/login-password-send-mail-confirm",
    name: "LoginPasswordSendMailConfirm",
    component: () => import("@/views/LoginPasswordSendMailConfirm.vue"),
  },
  {
    // TODO: パスワード再設定メールに記載のURLからのみ遷移可能なようにガード追加
    path: "/login-password-setting",
    name: "LoginPasswordSetting",
    component: () => import("@/views/LoginPasswordSetting.vue"),
    props: (route) => ({
      token: route.query.token,
      repository: new EmployeeRepository(),
    }),
  },
  {
    // TODO: パスワード再設定メールに記載のURLからのみ遷移可能なようにガード追加
    path: "/sms-authenticate",
    name: "SmsAuthenticate",
    props: () => ({
      repository: new SmsRepository(),
      loginRepository: new LoginRepository(),
    }),
    component: () => import("@/views/SmsAuthenticate.vue"),
  },
  {
    path: "/login-register",
    name: "LoginRegister",
    props: (route) => ({
      token: route.query.token,
      repository: new EmployeeRepository(),
      invitationRepository: new InvitationRepository(),
    }),
    component: () => import("@/views/LoginRegister.vue"),
    meta: { authRequired: false },
  },
  {
    path: "/login-register-complete",
    name: "LoginRegisterComplete",
    component: () => import("@/views/LoginRegisterComplete.vue"),
    meta: { authRequired: true },
  },
  {
    path: "/reset-email",
    name: "ResetEmail",
    props: (route) => ({
      token: route.query.token,
      repository: new EmployeeRepository(),
    }),
    component: () => import("@/views/ResetEmail.vue"),
    meta: { authRequired: false },
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("@/views/Home.vue"),
    props: () => ({
      accountRepository: new AccountRepository(),
      informationRepository: new InformationRepository(),
      applicationRepository: new ApplicationRepository(),
      balanceRepository: new BalanceRepository(),
      ganbRepository: new GanbRepository(),
    }),
    meta: { authRequired: true },
  },
  {
    path: "/account",
    name: "Account",
    component: () => import("@/views/Account.vue"),
    props: () => ({
      memberRepository: new MemberRepository(),
      employeeRepository: new EmployeeRepository(),
      serviceBillRepository: new ServiceBillRepository(),
      authInfoRepository: new AuthInfoRepository(),
    }),
    meta: { title: "各種設定", authRequired: true },
  },
  {
    path: "/application-list",
    name: "ApplicationList",
    component: () => import("@/views/ApplicationList.vue"),
    props: () => ({ repository: new ApplicationRepository() }),
    meta: { title: "申請状況", authRequired: true },
  },
  {
    path: "/application-approval-payment/:id",
    name: "ApplicationApprovalPayment",
    component: () => import("@/views/PaymentDetail.vue"),
    props: (route) => ({
      id: route.params.id,
      applicationRepository: new ApplicationRepository(),
      fileRepository: new FileRepository(),
      paymentRepository: new PaymentRepository(),
      smsRepository: new SmsRepository(),
    }),
    meta: {
      title: "支払い申請",
      breadcrumbs: ["申請状況"],
      authRequired: true,
    },
  },
  {
    path: "/application-approval-invoicing/:id",
    name: "ApplicationApprovalInvoicing",
    component: () => import("@/views/ApplicationApprovalInvoicing.vue"),
    props: (route) => ({
      id: route.params.id,
      repository: new InvoicingRepository(),
      applicationRepository: new ApplicationRepository(),
    }),
    meta: { title: "請求申請", breadcrumbs: ["申請状況"], authRequired: true },
  },
  {
    path: "/application-invoicing-edit/:id",
    name: "ApplicationInvoicingEdit",
    component: () => import("@/views/InvoicingEdit.vue"),
    props: (route) => ({
      id: route.params.id,
      repository: new InvoicingRepository(),
    }),
    meta: {
      title: "請求内容の変更",
      breadcrumbs: ["申請状況"],
      authRequired: true,
    },
  },
  {
    path: "/cashflow-list",
    name: "CashflowList",
    component: () => import("@/views/CashflowList.vue"),
    props: () => ({ repository: new BalanceRepository() }),
    meta: { title: "入出金明細", authRequired: true },
  },
  {
    path: "/employee-list",
    name: "EmployeeList",
    component: () => import("@/views/EmployeeList.vue"),
    props: () => ({ repository: new EmployeeRepository() }),
    meta: { title: "社員", authRequired: true },
  },
  {
    path: "/employee-invitation",
    name: "EmployeeInvitation",
    component: () => import("@/views/EmployeeInvitation.vue"),
    props: () => ({ repository: new InvitationRepository() }),
    meta: { title: "社員招待", breadcrumbs: ["社員"], authRequired: true },
  },
  {
    path: "/employee-edit/:id",
    name: "EmployeeEdit",
    component: () => import("@/views/EmployeeEdit.vue"),
    props: (route) => ({
      id: route.params.id,
      repository: new EmployeeRepository(),
      // emailResetRepository: new EmailResetRepository(),
      // smsRepository: new SmsRepository(),
    }),
    meta: {
      title: "社員情報の変更",
      breadcrumbs: ["社員"],
      authRequired: true,
    },
  },
  {
    path: "/employee-delete/:id",
    name: "EmployeeDelete",
    component: () => import("@/views/EmployeeDelete.vue"),
    props: (route) => ({
      id: route.params.id,
      repository: new EmployeeRepository(),
      applicationRepository: new ApplicationRepository(),
    }),
    meta: {
      title: "社員情報の削除",
      breadcrumbs: ["社員"],
      authRequired: true,
    },
  },
  {
    path: "/invoicing-list",
    name: "InvoicingList",
    component: () => import("@/views/InvoicingList.vue"),
    props: () => ({ repository: new InvoicingRepository() }),
    meta: { title: "請求", authRequired: true },
  },
  {
    path: "/invoicing-register",
    name: "InvoicingRegister",
    component: () => import("@/views/InvoicingRegister.vue"),
    props: () => ({
      repository: new InvoicingRepository(),
      applicationRepository: new ApplicationRepository(),
    }),
    meta: { title: "請求情報登録", breadcrumbs: ["請求"], authRequired: true },
  },
  {
    path: "/invoicing-detail/:id",
    name: "InvoicingDetail",
    component: () => import("@/views/InvoicingDetail.vue"),
    props: (route) => ({
      id: route.params.id,
      repository: new InvoicingRepository(),
      applicationRepository: new ApplicationRepository(),
    }),
    meta: { title: "請求詳細", breadcrumbs: ["請求"], authRequired: true },
  },
  {
    path: "/invoicing-edit/:id",
    name: "InvoicingEdit",
    component: () => import("@/views/InvoicingEdit.vue"),
    props: (route) => ({
      id: route.params.id,
      repository: new InvoicingRepository(),
    }),
    meta: {
      title: "請求内容の変更",
      breadcrumbs: ["請求"],
      authRequired: true,
    },
  },
  {
    path: "/payment-list",
    name: "PaymentList",
    component: () => import("@/views/PaymentList.vue"),
    props: () => ({
      repository: new PaymentRepository(),
      fileRepository: new FileRepository(),
    }),
    meta: { title: "支払い", authRequired: true },
  },
  {
    path: "/payment-register",
    name: "PaymentRegister",
    component: () => import("@/views/PaymentRegister.vue"),
    props: (route) => ({
      serviceBillId: route.query.service_bill_id,
      repository: new PaymentRepository(),
      applicationRepository: new ApplicationRepository(),
      smsRepository: new SmsRepository(),
      invoicingRepository: new InvoicingRepository(),
      fileRepository: new FileRepository(),
    }),
    meta: {
      title: "新規支払い登録",
      breadcrumbs: ["支払い"],
      authRequired: true,
    },
  },
  {
    path: "/payment-detail/:id",
    name: "PaymentDetail",
    component: () => import("@/views/PaymentDetail.vue"),
    props: (route) => ({
      id: route.params.id,
      applicationRepository: new ApplicationRepository(),
      fileRepository: new FileRepository(),
      paymentRepository: new PaymentRepository(),
      smsRepository: new SmsRepository(),
    }),
    meta: { title: "支払い詳細", breadcrumbs: ["支払い"], authRequired: true },
  },
  {
    path: "/payment-edit/:id",
    name: "PaymentEdit",
    component: () => import("@/views/PaymentEdit.vue"),
    props: (route) => ({
      id: route.params.id,
      fileRepository: new FileRepository(),
      applicationRepository: new ApplicationRepository(),
      paymentRepository: new PaymentRepository(),
      smsRepository: new SmsRepository(),
    }),
    meta: {
      title: "支払い内容の変更",
      breadcrumbs: ["支払い"],
      authRequired: true,
    },
  },
  {
    path: "/news-list",
    name: "NewsList",
    component: () => import("@/views/NewsList.vue"),
    props: () => ({
      repository: new InformationRepository(),
      notificationRepository: new NotificationRepository(),
    }),
    meta: { title: "お知らせ", authRequired: true },
  },
  {
    path: "/receive-invoicing-list",
    name: "ReceiveInvoicingList",
    component: () => import("@/views/ReceiveInvoicingList.vue"),
    props: () => ({ repository: new ReceivedInvoicingRepository() }),
    meta: { title: "受領請求", authRequired: true },
  },
  {
    path: "/receive-invoicing-detail",
    name: "ReceiveInvoicingDetail",
    component: () => import("@/views/ReceiveInvoicingDetail.vue"),
    props: () => ({
      repository: new PaymentRepository(),
      applicationRepository: new ApplicationRepository(),
    }),
    meta: {
      title: "受領請求詳細",
      breadcrumbs: ["受領請求"],
      authRequired: true,
    },
  },
  {
    path: "/workflow-list",
    name: "WorkflowList",
    component: () => import("@/views/WorkflowList.vue"),
    props: () => ({
      repository: new OperationLogRepository(),
      employeeRepository: new EmployeeRepository(),
    }),
    meta: { title: "処理履歴", authRequired: true },
  },
  {
    path: "/terms",
    name: "Terms",
    component: () => import("@/views/Terms.vue"),
    meta: { title: "利用規約" },
  },
  {
    path: "/faq",
    name: "Faq",
    component: () => import("@/views/Faq.vue"),
    meta: { title: "よくある質問" },
  },
  {
    path: "/external/oauth/authorize",
    name: "OAuthAuthorize",
    component: () => import("@/views/oauth/Authorize.vue"),
    props: (route) => ({
      repository: new OAuthRepository(),
      loginRepository: new LoginRepository(),
      params: {
        responseType: route.query.response_type,
        clientId: route.query.client_id,
        redirectUri: route.query.redirect_uri,
        state: route.query.state,
        codeChallenge: route.query.code_challenge,
        codeChallengeMethod: route.query.code_challenge_method,
      },
    }),
    meta: { title: "会員認証", authRequired: false },
  },
  {
    path: "/unsubscribe",
    name: "Unsubscribe",
    component: () => import("@/views/Unsubscribe.vue"),
    meta: { title: "解約", authRequired: true },
  },
  {
    path: "/unsubscribe-confirm",
    name: "UnsubscribeConfirm",
    component: () => import("@/views/UnsubscribeConfirm.vue"),
    props: () => ({
      memberRepository: new MemberRepository(),
      applicationRepository: new ApplicationRepository(),
    }),
    meta: { title: "解約", authRequired: true },
  },
  // TODO: 解約フローからのみ遷移可能なようにガード追加
  {
    path: "/unsubscribe-questionnaire/:id",
    name: "UnsubscribeQuestionnaire",
    component: () => import("@/views/UnsubscribeQuestionnaire.vue"),
    props: (route) => ({
      id: route.params.id,
      memberRepository: new MemberRepository(),
    }),
    meta: { title: "解約完了", authRequired: true },
  },
  {
    path: "/unsubscribe-complete",
    name: "UnsubscribeComplete",
    component: () => import("@/views/UnsubscribeComplete.vue"),
    meta: { title: "解約完了", authRequired: true },
  },
  {
    path: "/file-data-list",
    name: "FileDataList",
    component: () => import("@/views/FileDataList.vue"),
    props: () => ({
      repository: new FileRepository(),
    }),
    meta: { title: "データ連携", authRequired: true },
  },
  {
    path: "/file-data-upload",
    name: "FileDataUpload",
    component: () => import("@/views/FileDataUpload.vue"),
    meta: {
      title: "ファイル取込み",
      breadcrumbs: ["データ連携"],
      authRequired: true,
    },
  },
  {
    path: "/file-data-upload-confirm",
    name: "FileDataUploadConfirm",
    component: () => import("@/views/FileDataUploadConfirm.vue"),
    props: () => ({
      repository: new FileRepository(),
    }),
    meta: {
      title: "ファイル取込み",
      breadcrumbs: ["データ連携"],
      authRequired: true,
    },
  },
  {
    path: "/file-data-upload-complete",
    name: "FileDataUploadComplete",
    component: () => import("@/views/FileDataUploadComplete.vue"),
    props: true,
    meta: {
      title: "ファイル取込み",
      breadcrumbs: ["データ連携"],
      authRequired: true,
    },
  },
  {
    path: "/file-data-detail/:id",
    name: "FileDataDetail",
    component: () => import("@/views/PaymentDetail.vue"),
    props: (route) => ({
      id: route.params.id,
      applicationRepository: new ApplicationRepository(),
      fileRepository: new FileRepository(),
      paymentRepository: new PaymentRepository(),
      smsRepository: new SmsRepository(),
    }),
    meta: {
      title: "ファイル詳細",
      breadcrumbs: ["データ連携"],
      authRequired: true,
    },
  },
  {
    path: "/file-data-edit/:id",
    name: "FileDataEdit",
    component: () => import("@/views/FileDataEdit.vue"),
    props: (route) => ({
      id: route.params.id,
      repository: new FileRepository(),
    }),
    meta: {
      title: "ファイル情報の変更",
      breadcrumbs: ["データ連携"],
      authRequired: true,
    },
  },
  {
    path: "/error-service-bill",
    name: "ErrorServiceBill",
    component: () => import("@/views/ErrorServiceBill.vue"),
    meta: { authRequired: true },
  },
  {
    path: "/external/payment/authorize",
    name: "ExternalTransactionPassword",
    component: () => import("@/views/external/TransactionPassword.vue"),
    props: (route) => ({
      token: route.query.auth_url_token,
      repository: new ExternalRepository(),
    }),
    meta: {
      title: "外部サービス支払認証",
      authRequired: false,
    },
  },
  {
    path: "/external/payment/sms-authenticate",
    name: "ExternalSmsAuthenticate",
    component: () => import("@/views/external/SmsAuthenticate.vue"),
    props: (route) => ({
      token: route.query.token,
      repository: new ExternalRepository(),
    }),
    meta: {
      title: "外部サービス支払認証",
      authRequired: false,
    },
  },
  {
    path: "*",
    name: "NotFound",
    component: () => import("@/views/NotFound.vue"),
  },
];

guardInjection(routes);

const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) return savedPosition;
  if (to.hash) return { el: to.hash };

  return { x: 0, y: 0 };
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior,
});

router.beforeEach((to, from, next) => {
  if (to.name === "NotFound") {
    if (authenticate()) next();
    else next({ path: "/login", replace: true });
    return;
  }
  const notAuthRequiredPath = to.meta && !to.meta.authRequired;
  if (notAuthRequiredPath) {
    noAuthFlow(to, next);
    return;
  }
  const auth = authenticate();
  if (auth) authFlow(to, from, next);
  else noAuthFlow(to, next);
});

export default router;
