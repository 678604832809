import { applicationListItemFromJson } from "@/dto/application";
import Repository from "@/infrastructure/api";
import { ApplicationList } from "@/model/application/application_list";

const resource = "applications";
export class ApplicationRepository {
  fetchApplicationList = async (params, config = {}) => {
    const { data } = await Repository.get(`${resource}`, {
      ...{ params },
      ...config,
    });
    const dataCount = data["data_count"];
    const currentApplicationList = data["current_application_list"];
    const listItems = currentApplicationList.map((json) =>
      applicationListItemFromJson(json),
    );
    return ApplicationList.valueOf({ dataCount, listItems });
  };

  fetchUncompleted = async () => {
    const { data } = await Repository.get(`${resource}/uncompleted`);

    return data;
  };

  update = async (payload) => {
    await Repository.put(`${resource}`, payload);
  };

  registerPayment = async (payload) => {
    const { data } = await Repository.post(`${resource}/payment`, payload);
    return data.file_id;
  };

  registerInvoicing = async (payload) => {
    await Repository.post(`${resource}/invoicing`, payload);
  };
}
