// import { dummyWaitForLoading } from "@/infrastructure/dummy_repository_functions";
// import { ApplicationDetail } from "@/model/application/application_detail";
// import { ApplicationHistoryItem } from "@/model/application/application_history_item";
// import { BusinessAssociate } from "@/model/business_associate/business_associate";
// import { Employee } from "@/model/employee/employee";
// import { OperationEmployee } from "@/model/employee/operation_employee";
// import { PaymentDetail } from "@/model/payment/payment_detail";
// import { PaymentList } from "@/model/payment/payment_list";
// import { PaymentListItem } from "@/model/payment/payment_list_item";
// import { ReceivedInvoicing } from "@/model/received_invoicing/received_invoicing";
// import { AmountOfMoney } from "@/model/values/amount_of_money";
// import { ApplicationType } from "@/model/values/application_type";
// import { Date } from "@/model/values/date";
// import { DateTime } from "@/model/values/date_time";
// import { Operation } from "@/model/values/operation";
// import { Status } from "@/model/values/status";

// export class PaymentDummyRepository {
//   fetchPaymentList = async (params = {}) => {
//     console.log(`params is ${JSON.stringify(params)}`);
//     await dummyWaitForLoading(0);
//     const type = ApplicationType.payment();
//     const listItems = [
//       PaymentListItem.valueOf({
//         id: 1,
//         paymentNumber: "000120100111",
//         paymentDate: Date.valueOf("2021/1/31"),
//         receivedInvoicing: ReceivedInvoicing.valueOf({
//           billingDate: Date.valueOf("2020/12/25"),
//         }),
//         businessAssociate: BusinessAssociate.valueOf({
//           displayCompanyName: "株式会社pring",
//         }),
//         title: "12月ホスティングサービス利用分",
//         amountOfMoney: AmountOfMoney.valueOf(14500000),
//         application: ApplicationDetail.valueOf({
//           type,
//           status: Status.valueOf("remand"),
//         }),
//       }),
//       PaymentListItem.valueOf({
//         id: 2,
//         paymentNumber: "000120100111",
//         paymentDate: Date.valueOf("2021/1/31"),
//         receivedInvoicing: ReceivedInvoicing.empty(),
//         businessAssociate: BusinessAssociate.valueOf({
//           displayCompanyName:
//             "株式会社ハイパーマネジメンコーポレーショングループ",
//         }),
//         title: "12月ホスティングサービス利用分",
//         amountOfMoney: AmountOfMoney.valueOf(2150000),
//         application: ApplicationDetail.valueOf({
//           type,
//           status: Status.valueOf("applying"),
//         }),
//       }),
//     ];
//     return PaymentList.valueOf({ dataCount: listItems.length, listItems });
//   };
//   register = async (payload) => {
//     console.log(`payload is ${JSON.stringify(payload)}`);
//     await dummyWaitForLoading(0);
//   };
//   update = async (payload) => {
//     console.log(`payload is ${JSON.stringify(payload)}`);
//     await dummyWaitForLoading();
//   };

//   fetchPayment = async (paymentId) => {
//     console.log(`paymentId is ${paymentId}`);
//     await dummyWaitForLoading(0);

//     return PaymentDetail.valueOf({
//       id: paymentId,
//       paymentNumber: "000120100111",
//       paymentDate: Date.valueOf("2021/1/31"),
//       billingDate: Date.valueOf("2020/12/25"),
//       businessAssociate: BusinessAssociate.valueOf({
//         id: 1000005,
//         displayCompanyName: "株式会社pring",
//       }),
//       paymentTitle: "12月ホスティングサービス利用分",
//       amountOfMoney: AmountOfMoney.valueOf(14500000),
//       status: Status.valueOf("applying"),
//       title: "ｘｘｘｘの支払い",
//       memo: "11月の支払い分です",
//       receivedInvoicing: ReceivedInvoicing.valueOf({
//         id: 1,
//         invoicingNumber: "000120100111",
//         billingDate: Date.valueOf("2021/12/12"),
//         title: "2020年11月分の支払い",
//         deadlineOn: Date.valueOf("2022/09/01"),
//         memo: "あああああ",
//       }),
//       application: ApplicationDetail.valueOf({
//         id: 1,
//         approvalDeadline: Date.valueOf("2021/12/12"),
//         status: Status.valueOf("payment_reservation"),
//         type: ApplicationType.valueOf("payment"),
//         comment:
//           "今月から契約内容変更で金額が変わりました。確認＆修正をお願いします。",
//         applicant: Employee.valueOf({
//           lastName: "廣井",
//           firstName: "海",
//         }),
//       }),
//       applicationHistory: [
//         ApplicationHistoryItem.valueOf({
//           id: 1,
//           operationAt: DateTime.valueOf("2021-12-12T15:32:25.000Z"),
//           status: Status.valueOf("applying"),
//           operation: Operation.valueOf("applying"),
//           type: ApplicationType.valueOf("payment"),
//           operationEmployee: OperationEmployee.valueOf({
//             lastName: "廣井",
//             firstName: "海",
//           }),
//           comment:
//             "今月から契約内容変更で金額が変わりました。確認＆修正をお願いします。",
//         }),
//         ApplicationHistoryItem.valueOf({
//           id: 2,
//           operationAt: DateTime.valueOf("2021-12-12T15:32:25.000Z"),
//           status: Status.valueOf("applying"),
//           type: ApplicationType.valueOf("payment"),
//           operation: Operation.valueOf("applying"),
//           operationEmployee: OperationEmployee.valueOf({
//             lastName: "土田",
//             firstName: "健太郎",
//           }),
//           comment:
//             "11月分のシステムメンテナンス費用の支払です。確認お願いします。11月分のシステムメンテナンス費用の支払です。確認お願いします。11月分のシステムメンテナンス費用の支払です。確認お願いします。11月分のシステムメンテナンス費用の支払です。確認お願いします。11月分のシステムメンテナンス費用の支払です。確認お願いします。",
//         }),
//       ],
//     });
//   };
// }
