import { PageNation } from "@/model/form/page_nation";
import { DateRange } from "@/model/values/date_range";
import { toSnake } from "@/service/convert_service";

const properties = {
  status: [],
  keyword: "",
  paymentDate: DateRange.empty(),
  pageNation: PageNation.firstPage(),
  sortDirection: "desc",
  sortColumn: "id",
  fileDetailShow: false,
};

export class PaymentListSearchForm {
  constructor({
    status,
    keyword,
    paymentDate,
    fileDetailShow,
    pageNation,
    sortColumn,
    sortDirection,
  }) {
    this.status = status;
    this.keyword = keyword;
    this.paymentDate = paymentDate;
    this.fileDetailShow = fileDetailShow;
    this.pageNation = pageNation;
    this.sortColumn = sortColumn;
    this.sortDirection = sortDirection;
  }

  static valueOf(items = {}) {
    return new PaymentListSearchForm({ ...properties, ...items });
  }

  static empty() {
    return PaymentListSearchForm.valueOf(properties);
  }

  clear() {
    const { pageNation } = this;
    return PaymentListSearchForm.valueOf({ pageNation });
  }

  updateKeyword(value) {
    return PaymentListSearchForm.valueOf({ ...this, ...{ keyword: value } });
  }
  updateStatus(value) {
    return PaymentListSearchForm.valueOf({ ...this, ...{ status: value } });
  }

  updatePaymentDate(value) {
    const paymentDate = this.paymentDate.updateDateRange(value);
    return PaymentListSearchForm.valueOf({ ...this, ...{ paymentDate } });
  }

  updateFileDetailShow() {
    return PaymentListSearchForm.valueOf({
      ...this,
      ...{ fileDetailShow: !this.fileDetailShow },
    });
  }

  updateNumberOfData(value) {
    const pageNation = this.pageNation.updateNumberOfData(value);
    return PaymentListSearchForm.valueOf({ ...this, ...{ pageNation } });
  }
  updatePage(value) {
    const pageNation = this.pageNation.updatePage(value);
    return PaymentListSearchForm.valueOf({ ...this, ...{ pageNation } });
  }

  updateDataCount(value) {
    const pageNation = this.pageNation.updateDataCount(value);
    return PaymentListSearchForm.valueOf({ ...this, ...{ pageNation } });
  }

  updateSortDirection() {
    const value = this.sortDirection === "desc" ? "asc" : "desc";
    return PaymentListSearchForm.valueOf({
      ...this,
      ...{ sortDirection: value },
    });
  }

  updateSortColumn(value) {
    return PaymentListSearchForm.valueOf({ ...this, ...{ sortColumn: value } });
  }

  toJson(isCsv) {
    const {
      status,
      paymentDate,
      keyword,
      pageNation,
      sortColumn,
      sortDirection,
    } = this;
    const params = isCsv ? {} : pageNation.toJson();
    if (status.length) params.status = status;
    if (keyword) params.keyword = keyword;
    if (paymentDate.isValid) {
      params.payment_date_from = paymentDate.start.toParam();
      params.payment_date_to = paymentDate.end.toParam();
    }
    params.sort_direction = sortDirection;
    params.sort_column = sortColumn;

    return toSnake(params);
  }
}
