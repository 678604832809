import { accountType } from "@/const/file_upload";
import { AmountOfMoney } from "@/model/values/amount_of_money";
import { Bank } from "../bank/bank";
import { Branch } from "../bank/branch";
import { Status } from "../values/status";

const properties = {
  lineNumber: 0,
  type: "",
  amountOfMoney: AmountOfMoney.empty(),
  fee: AmountOfMoney.empty(),
  status: Status.empty(),
  accountType: accountType[0],
  accountNumber: "",
  bank: Bank.empty(),
  branch: Branch.empty(),
  clientSpecifiedId: "",
  recipientNameKana: "",
  isExist: true,
  isChanged: false,
  errorReason: "",
};

export class FilePaymentDetail {
  static valueOf(items = properties) {
    return new FilePaymentDetail({ ...properties, ...items });
  }
  static empty() {
    return FilePaymentDetail.valueOf({});
  }
  constructor(items = properties) {
    Object.assign(this, items);
  }
}
