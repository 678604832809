import { dummyWaitForLoading } from "@/infrastructure/dummy_repository_functions";
import { ApplicationList } from "@/model/application/application_list";
import { ApplicationListItem } from "@/model/application/application_list_item";
import { ApplicationType } from "@/model/values/application_type";
import { Date } from "@/model/values/date";
import { Status } from "@/model/values/status";

export class ApplicationDummyRepository {
  fetchApplicationList = async (params) => {
    console.log(`params is ${JSON.stringify(params)}`);
    await dummyWaitForLoading(500);

    const listItems = [
      ApplicationListItem.valueOf({
        id: 1,
        targetId: 1,
        type: ApplicationType.valueOf("payment"),
        approvalDeadline: Date.valueOf("2021-03-21"),
        status: Status.valueOf("applying"),
        title: "11月分",
      }),
      ApplicationListItem.valueOf({
        id: 99,
        targetId: 2,
        type: ApplicationType.valueOf("invoicing"),
        approvalDeadline: Date.valueOf("2021-08-01"),
        status: Status.valueOf("get_back"),
        title: "10月分",
      }),
      ApplicationListItem.valueOf({
        id: 91,
        targetId: 3,
        type: ApplicationType.valueOf("invoicing"),
        approvalDeadline: Date.valueOf("2021-08-01"),
        status: Status.valueOf("applying"),
        title: "９月分",
      }),
    ];

    return ApplicationList.valueOf({ dataCount: listItems.length, listItems });
  };

  update = async (payload) => {
    console.log(`payload is ${JSON.stringify(payload)}`);
    await dummyWaitForLoading(0);
  };

  registerPayment = async (payload) => {
    console.log(`payload is ${JSON.stringify(payload)}`);
    await dummyWaitForLoading(0);
  };

  registerInvoicing = async (payload) => {
    console.log(`payload is ${JSON.stringify(payload)}`);
    await dummyWaitForLoading(0);
  };
}
