import {
  fileListItemFromJson,
  fileDetailFromJson,
  paymentEditFromJson,
  fileDetailEditFromJson,
} from "@/dto/file";
import Repository from "@/infrastructure/api";
import { FileDataList } from "@/model/file_data/file_data_list";

const resource = "files";
export class FileRepository {
  postFile = async (payload) => {
    const { data } = await Repository.post(`${resource}/payment`, payload);
    return {
      fileId: data["file_id"],
      paymentId: data["sequential_id"],
    };
  };
  fetchList = async (params) => {
    const { data } = await Repository.get(`${resource}`, { params });
    const dataCount = data["data_count"];
    const currentFileList = data["current_file_list"];
    const listItems = currentFileList.map((json) => fileListItemFromJson(json));
    return FileDataList.valueOf({ dataCount, listItems });
  };
  fetchDetail = async (id) => {
    const { data } = await Repository.get(`${resource}/${id}`);
    return fileDetailFromJson(data);
  };
  fetchEdit = async (id) => {
    const { data } = await Repository.get(`${resource}/${id}`);
    return fileDetailEditFromJson(data);
  };
  fetchDetailEdit = async (id) => {
    const { data } = await Repository.get(`${resource}/${id}`);
    return paymentEditFromJson(data);
  };
  update = async (id, payload) =>
    await Repository.put(`${resource}/${id}`, payload);
  updateDetail = async (id, payload) =>
    await Repository.put(`${resource}/${id}/details`, payload);
  delete = async (id) => await Repository.delete(`${resource}/${id}`);
  cancel = async (id) => await Repository.post(`${resource}/${id}/cancel`);
  exportFilesCsv = async (payload) => {
    const { data } = await Repository.post(
      `${resource}/export_files_csv?${payload}`,
    );
    return data;
  };
  convertToCsv = async (payload) => {
    const { data } = await Repository.post(
      `${resource}/convert_to_csv`,
      payload,
    );
    return data;
  };
  uploadAttachmentFiles = async (id, payload) => {
    await Repository.post(`${resource}/${id}/attachments`, payload);
  };
  fetchAttachmentFileUrl = async (fileId, attachmentId) => {
    const { data } = await Repository.post(
      `${resource}/${fileId}/attachments/${attachmentId}/create_presigned_url`,
      {
        expires_in: 3600,
      },
    );

    return data.attachment_file_url;
  };
  deleteAttachmentFile = async (fileId, attachmentId) => {
    await Repository.delete(
      `${resource}/${fileId}/attachments/${attachmentId}`,
    );
  };
}
