import { dummyWaitForLoading } from "@/infrastructure/dummy_repository_functions";
import { AuthInfo } from "@/model/auth_info/auth_info";
import { Employee } from "@/model/employee/employee";
import { Member } from "@/model/member/member";
import { WorkflowPermission } from "@/model/role/workflow_permission";
import { FunctionPermission } from "@/model/values/function_permission";
import { Permission } from "@/model/values/permission";
import { Role } from "@/model/values/role";

export class DummyAuthInfoRepository {
  fetchAuthInfo = async () => {
    console.log(`fetch auth info`);
    await dummyWaitForLoading();

    const roles = [Role.valueOf("applicant"), Role.valueOf("approver")];
    const functionsPermission = ["payment_save_self", "invoicing_save_self"];
    const employee = Employee.valueOf({
      employeeId: 1,
      firstName: "一郎",
      lastName: "蘇陸",
      lastNameKana: "そりっく",
      firstNameKana: "いちろう",
      birthday: "2019-08-24",
      permission: Permission.valueOf({
        workflowPermission: WorkflowPermission.valueOf({ roles }),
        functionsPermission: functionsPermission.map((item) =>
          FunctionPermission.valueOf(item),
        ),
      }),
    });
    const company = Member.valueOf({
      displayCompanyName: "test1",
      isValidB2bCoinToken: true,
    });
    return AuthInfo.valueOf({
      employee,
      company,
    });
  };
}
